import React from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Home } from "components";
import {
  About,
  Blog,
  Blogs,
  Certificate,
  Contact,
  Hotel,
  NotFound,
  PolicyOne,
  PolicyThree,
  PolicyTwo,
  PQRS,
  Questions,
  Sagrilaft,
  EthicalLine,
  Payments,
} from "page";
import MainLayout from "layout/MainLayout";
import MenuRobot from "common/MenuRobot";
import Index from "page/Admin/Index";
import CreateBanner from "page/Admin/CreateBanner";
import DashboardBanners from "page/Admin/DashboardBanners";
import AddValueAffiliates from "page/Admin/affiliates/AddValueAffiliates";
import ManagerContent from "page/Admin/ManagerContent";
import TermsAndConditions from "page/TermsAndConditions";
import ViewAnalitics from "page/Admin/ViewAnalitics";
import DashboardAffiliates from "page/Admin/affiliates/DashboardAffiliates";
import InstagramForm from "page/Admin/instagramVideos/InstagramForm";
import ScrollToTop from "common/scroll/ScrollToTop";
import { PageInstagram } from "page/Admin/instagramVideos/PageInstagram";
import { PageTestimonials } from "page/Admin/testimonials/PageTestimonials";
import TestimonialsForm from "page/Admin/testimonials/TestimonialsForm";
import { PageQuestions } from "page/Admin/questions/PageQuestions";
import QuestionsForm from "page/Admin/questions/QuestionsForm";
import MainPopUp from "components/pop-up/MainPopUp";

const RoutesGAC = () => {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <MainPopUp />
        <MainLayout>
          <MenuRobot />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/sagrilaft" element={<Sagrilaft />} />
            <Route
              path="/terminos-y-condiciones"
              element={<TermsAndConditions />}
            />
            <Route
              path="/analiticas-grupo-alianza"
              element={<ViewAnalitics />}
            />
            <Route path="/about" element={<About />} />
            <Route path="/pqrs" element={<PQRS />} />
            <Route path="/document/:url" element={<Certificate />} />
            <Route
              path="/politica-de-ley-de-retracto"
              element={<PolicyOne />}
            />
            <Route
              path="/politica-de-proteccion-de-menores"
              element={<PolicyTwo />}
            />
            <Route path="/bio-seguridad" element={<PolicyThree />} />
            <Route path="/contacto" element={<Contact />} />
            <Route path="/questions" element={<Questions />} />
            <Route path="/blog" element={<Blogs />} />
            <Route path="/blog/:id" element={<Blog />} />
            <Route path="/:destino/:hotel" element={<Hotel />} />
            <Route path="/pagos" element={<Payments />} />
            <Route
              path="/mercadopago"
              element={<Navigate to="/pagos" replace />}
            />

            <Route path="/linea-etica" element={<EthicalLine />} />
            <Route path="*" element={<NotFound />} />

            {/* rutas gestión de contenido */}
            <Route
              path="/d77cdf4f4ab48f705fbed94d26c7ee03831add4b9cbe2a4c7c578f8cc7361215"
              element={<Index />}
            >
              <Route path="" element={<ManagerContent />} />
              <Route path="banners" element={<DashboardBanners />} />
              <Route path="banners/create-banners" element={<CreateBanner />} />
              <Route
                path="dashboard-affiliates"
                element={<DashboardAffiliates />}
              />
              <Route
                path="add-value-affiliates"
                element={<AddValueAffiliates />}
              />
              <Route path="instagram" element={<PageInstagram />} />
              <Route path="history-instagram" element={<InstagramForm />} />
              <Route path="testimonials" element={<PageTestimonials />} />
              <Route path="create-testimony" element={<TestimonialsForm />} />
              <Route path="questions" element={<PageQuestions />} />
              <Route path="create-questions" element={<QuestionsForm />} />
            </Route>
          </Routes>
        </MainLayout>
      </BrowserRouter>
    </>
  );
};

export default RoutesGAC;
