import { apiConfig } from "./apiConfig";

const getVideoInstagramGac = async () => {
  return await apiConfig.get("/histories");
};
const saveVideo = async (payload) => {
  return await apiConfig.post("/histories/create", { code: payload });
};
const deleteHistory = async (id) => {
  return await apiConfig.delete(`histories/delete/${id}`);
};
const updateHistory = async (id, payload) => {
  return await apiConfig.patch(`histories/update/${id}`, payload);
};

export { saveVideo, getVideoInstagramGac, deleteHistory, updateHistory };
