import React from "react";

const TermsAndConditions = () => {
  return (
    <section className="ContentEthicalLine">
      <h2 className="title-policy text-center mb-5">
        Sección términos y condiciones promocionales
      </h2>
      <article>
        <h4 className="title-policy">Hotel Riu República</h4>
        <p>
          Tarifa válida para dos personas únicamente para uso en el
          <strong> Hotel Riu República - Adults Only - All Inclusive</strong>,
          en el destino de
          <strong> Punta Cana, República Dominicana. </strong>Ciclo de viaje:
          cuatro (4) noches y cinco (5) días. Incluye: Alimentación (desayuno,
          almuerzo, cena, botanas las 24 horas), Bebidas: bebidas alcohólicas
          nacionales y selectas marcas internacionales las 24 horas, jugos,
          cocteles y refrescos.
        </p>
        <p>
          Tarifa sujeta a disponibilidad hotelera: Disponibilidad habitacional
          50 unidades. // Tarifa válida para compras del{" "}
          <strong>28 de diciembre del 2024 al 31 de diciembre del 2024.</strong>{" "}
          Válida para ciclo de viaje comprendido entre el 01 de enero de 2025 al
          30 de marzo del 2025, teniendo como fecha límite de alojamiento la
          noche del 29 al 30 de marzo del 2025.
        </p>
        <p>
          Tarifa para uso en acomodación doble, para acomodación múltiple aplica
          suplemento de tarifa/cargo adicional o diferencia de tarifa, para
          acomodación sencilla o de única persona aplica suplemento de
          tarifa/cargo adicional o diferencia de tarifa por pérdida de
          acomodación. Aplica para viaje en temporada baja, no aplica para uso
          en puentes festivos (entiéndase puente festivo cuando el viernes o
          lunes dentro del ciclo de viaje es festivo o feriado), semana santa,
          receso escolar, fin de año.
        </p>
        <p>
          No incluye: Tiquetes de transporte terrestre ni aéreos, Traslados
          terrestres, alimentación no relacionada, impuestos en destino que
          correspondan, asistencia médica, cargos no especificados en el
          programa y cualquier otro servicio no relacionado en los presentes
          términos y condiciones. Tarifa exclusiva para afiliados a Grupo
          Alianza Colombia S.A.S. como beneficio adquirido a través de su
          membresía vacacional. Pago directo a Grupo Alianza Colombia S.A.S. a
          través de la plataforma Alianza Priority.
        </p>
      </article>
      <article>
        <h4 className="title-policy">Hotel Ms Cuidad Jardín</h4>
        <p>
          Tarifa válida para dos personas únicamente para uso en el
          <strong> Hotel Ms Cuidad Jardín</strong>, en el destino de
          <strong> Santiago de Cali, Colombia. </strong>Ciclo de viaje: cinco
          (5) noches y seis (6) días. Incluye: alimentación: (desayuno tipo
          buffet); alojamiento de cinco (5) noches y seis (6) días. Tarifa
          sujeta a disponibilidad hotelera, disponibilidad habitacional 50
          unidades.
        </p>
        <p>
          Tarifa válida para compras del{" "}
          <strong>06 de diciembre del 2024</strong> al{" "}
          <strong>30 de diciembre del 2024.</strong> Válida para ciclo de viaje
          comprendido entre el 06 de diciembre de 2024 al 31 de agosto de 2025
          teniendo como fecha límite de alojamiento la noche del 30 al 31 de
          agosto de 2025.
        </p>
        <p>
          Tarifa para uso en acomodación doble, para acomodación múltiple aplica
          suplemento de tarifa/cargo adicional o diferencia de tarifa, para
          acomodación sencilla o de única persona aplica suplemento de
          tarifa/cargo adicional o diferencia de tarifa por pérdida de
          acomodación. Aplica para viaje en temporada baja, No aplica para uso
          en puentes festivos (entiéndase puente festivo cuando el viernes o
          lunes dentro del ciclo de viaje es festivo o feriado), semana santa,
          receso escolar, fin de año. No incluye: Tiquetes de transporte
          terrestre ni aéreos, Traslados terrestres, alimentación no
          relacionada, Impuestos en destino que correspondan, asistencia médica,
          cargos no especificados en el programa y cualquier otro servicio no
          relacionado en los presentes términos y condiciones. Tarifa exclusiva
          para afiliados a Grupo Alianza Colombia S.A.S. como beneficio
          adquirido a través de su membresía vacacional. Pago directo a Grupo
          Alianza Colombia S.A.S. a través de la plataforma Alianza Priority.
        </p>
      </article>

      <article>
        <h4 className="title-policy">Promoción Cancún:</h4>
        <p>
          Tarifa válida por persona únicamente para uso en el Hotel Royal
          Solaris Cancun Resort Marina & Spa All Inclusive, en el destino de
          Cancún, México. Ciclo de viaje: cinco(5) noches y seis (6) días.
          Incluye: Alimentación (desayuno, almuerzo, cena), Bebidas: bebidas
          alcohólicas nacionales y selectas marcas internacionales las 24 horas,
          jugos, cocteles y refrescos.Tarifa sujeta a disponibilidad hotelera:
          Disponibilidad habitacional 50 unidades. // Tarifa válida para compras
          del 10 de enero del 2025 al 25 de enero del 2025. Válida para ciclo de
          viaje comprendido entre el 20 de enero de 2025 al 15 de abril de 2025,
          teniendo como fecha límite de alojamiento la noche del 14 al 15 de
          abril de 2025. Tarifa para uso en acomodación doble, para acomodación
          múltiple aplica suplemento de tarifa/cargo adicional o diferencia de
          tarifa, para acomodación sencilla o de única persona aplica suplemento
          de tarifa/cargo adicional o diferencia de tarifa por pérdida de
          acomodación. Aplica para viaje en temporada baja, no aplica para uso
          en puentes festivos (entiéndase puente festivo cuando el viernes o
          lunes dentro del ciclo de viaje es festivo o feriado), semana santa,
          receso escolar, fin de año. No incluye: Tiquetes de transporte
          terrestre ni aéreos, Traslados terrestres, alimentación no
          relacionada, impuestos en destino que correspondan, asistencia médica,
          cargos no especificados en el programa y cualquier otro servicio no
          relacionado en los presentes términos y condiciones. Tarifa exclusiva
          para afiliados a Grupo Alianza Colombia S.A.S. como beneficio
          adquirido a través de su membresía vacacional. Pago directo a Grupo
          Alianza Colombia S.A.S
        </p>
      </article>

      <article>
        <h4 className="title-policy">Promoción Panamá:</h4>
        <p>
          Tarifa válida por persona únicamente para uso en el Hotel Grand
          Decameron Panamá, A Trademark All-Inclusive Resort, en el destino de
          Panamá. Ciclo de viaje: tres (3) noches y cuatro (4) días. Incluye:
          Alimentación (desayuno, almuerzo, cena), Bebidas: bebidas alcohólicas
          nacionales y selectas marcas internacionales las 24 horas, jugos,
          cocteles y refrescos.Tarifa sujeta a disponibilidad hotelera:
          Disponibilidad habitacional 50 unidades. // Tarifa válida para compras
          del 10 de enero del 2025 al 25 de enero del 2025. Válida para ciclo de
          viaje comprendido entre el 20 de enero de 2025 al 15 de abril de 2025,
          teniendo como fecha límite de alojamiento la noche del 14 al 15 de
          abril de 2025. Tarifa para uso en acomodación doble, para acomodación
          múltiple aplica suplemento de tarifa/cargo adicional o diferencia de
          tarifa, para acomodación sencilla o de única persona aplica suplemento
          de tarifa/cargo adicional o diferencia de tarifa por pérdida de
          acomodación. Aplica para viaje en temporada baja, no aplica para uso
          en puentes festivos (entiéndase puente festivo cuando el viernes o
          lunes dentro del ciclo de viaje es festivo o feriado), semana santa,
          receso escolar, fin de año. No incluye: Tiquetes de transporte
          terrestre ni aéreos, Traslados terrestres, alimentación no
          relacionada, impuestos en destino que correspondan, asistencia médica,
          cargos no especificados en el programa y cualquier otro servicio no
          relacionado en los presentes términos y condiciones. Tarifa exclusiva
          para afiliados a Grupo Alianza Colombia S.A.S. como beneficio
          adquirido a través de su membresía vacacional. Pago directo a Grupo
          Alianza Colombia S.A.S
        </p>
      </article>
      <article>
        <h4 className="title-policy">Promoción Cartagena:</h4>
        <p>
          Tarifa válida para dos personas únicamente para uso en el Hotel Dorado
          Punta Arenas, en el destino de Cartagena, Colombia. Ciclo de viaje:
          tres (3) noches y cuatro (4) días. Incluye: Alimentación (desayuno).
          Tarifa sujeta a disponibilidad hotelera: Disponibilidad habitacional
          50 unidades. // Tarifa válida para compras del 10 de enero del 2025 al
          25 de enero del 2025. Válida para ciclo de viaje comprendido entre el
          20 de enero de 2025 al 15 de abril de 2025, teniendo como fecha límite
          de alojamiento la noche del 14 al 15 de abril de 2025. Tarifa para uso
          en acomodación doble, para acomodación múltiple aplica suplemento de
          tarifa/cargo adicional o diferencia de tarifa, para acomodación
          sencilla o de única persona aplica suplemento de tarifa/cargo
          adicional o diferencia de tarifa por pérdida de acomodación. Aplica
          para viaje en temporada baja, no aplica para uso en puentes festivos
          (entiéndase puente festivo cuando el viernes o lunes dentro del ciclo
          de viaje es festivo o feriado), semana santa, receso escolar, fin de
          año. No incluye: Tiquetes de transporte terrestre ni aéreos, Traslados
          terrestres, alimentación no relacionada, impuestos en destino que
          correspondan, asistencia médica, cargos no especificados en el
          programa y cualquier otro servicio no relacionado en los presentes
          términos y condiciones. Tarifa exclusiva para afiliados a Grupo
          Alianza Colombia S.A.S. como beneficio adquirido a través de su
          membresía vacacional. Pago directo a Grupo Alianza Colombia S.A.S
        </p>
      </article>
      <article>
        <h4 className="title-policy">Promoción Medellin:</h4>
        <p>
          Tarifa válida para dos personas únicamente para uso en el Hotel
          Viaggio Medellín, en el destino de Medellín, Colombia. Ciclo de viaje:
          dos (2) noches y tres (3) días. Incluye: Alimentación (desayuno).
          Tarifa sujeta a disponibilidad hotelera: Disponibilidad habitacional
          50 unidades. // Tarifa válida para compras del 10 de enero del 2025 al
          25 de enero del 2025. Válida para ciclo de viaje comprendido entre el
          20 de enero de 2025 al 15 de abril de 2025, teniendo como fecha límite
          de alojamiento la noche del 14 al 15 de abril de 2025. Tarifa para uso
          en acomodación doble, para acomodación múltiple aplica suplemento de
          tarifa/cargo adicional o diferencia de tarifa, para acomodación
          sencilla o de única persona aplica suplemento de tarifa/cargo
          adicional o diferencia de tarifa por pérdida de acomodación. Aplica
          para viaje en temporada baja, no aplica para uso en puentes festivos
          (entiéndase puente festivo cuando el viernes o lunes dentro del ciclo
          de viaje es festivo o feriado), semana santa, receso escolar, fin de
          año. No incluye: Tiquetes de transporte terrestre ni aéreos, Traslados
          terrestres, alimentación no relacionada, impuestos en destino que
          correspondan, asistencia médica, cargos no especificados en el
          programa y cualquier otro servicio no relacionado en los presentes
          términos y condiciones. Tarifa exclusiva para afiliados a Grupo
          Alianza Colombia S.A.S. como beneficio adquirido a través de su
          membresía vacacional. Pago directo a Grupo Alianza Colombia S.A.S
        </p>
      </article>
    </section>
  );
};

export default TermsAndConditions;
