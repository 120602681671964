import { apiConfig } from "./apiConfig";

const getTestimonials = async () => {
  return await apiConfig.get("/testimonials");
};
const createTestimony = async (payload) => {
  return await apiConfig.post("/testimonials/create", payload);
};
const deleteTestimony = async (id) => {
  return await apiConfig.delete(`testimonials/delete/${id}`);
};
const updateTestimony = async (id, payload) => {
  return await apiConfig.patch(`testimonials/update/${id}`, payload);
};

export { getTestimonials, createTestimony, deleteTestimony, updateTestimony };
