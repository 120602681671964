import { apiConfig } from "./apiConfig";

const getQuestions = async () => {
  return await apiConfig.get("/questions");
};
const createQuestion = async (payload) => {
  return await apiConfig.post("/questions/create", payload);
};
const deleteQuestion = async (id) => {
  return await apiConfig.delete(`questions/delete/${id}`);
};
const updateQuestion = async (id, payload) => {
  return await apiConfig.patch(`questions/update/${id}`, payload);
};

export { getQuestions, createQuestion, deleteQuestion, updateQuestion };
