import React, { useEffect, useState } from "react";
import OutlinedText from "./OutlinedText";
import Slider from "components/video-slider/Slider";
import { getTestimonials } from "services/testimonials";
import ImageTestimonials from "assets/images/questions/testimonios.webp";
import "styles/testimonials.css";

const Testimonials = () => {
  const [testimonials, setTestimonials] = useState(null);

  const listTestimonials = async () => {
    try {
      const { data } = await getTestimonials();
      setTestimonials(data);
    } catch (error) {
      console.log(error);
      alert("No se pudo obtener el listado de setTestimonials");
    }
  };

  useEffect(() => {
    listTestimonials();
  }, []);

  return (
    <section className="section-testimonials">
      <OutlinedText
        position={true}
        positionTop1="30px"
        left_text1="1%"
        text1="Testimonios"
        text2="Testimonios"
        color="var(--azul-gac)"
        positionTop2="0px"
        left_text2="0%"
      />

      <article>
        <Slider
          showIndicators={true}
          showButtons={true}
          arrowColor="var(--azul-gac)"
          colorIndicator="var(--azul-gac)"
          position="relative"
        >
          {testimonials?.map(({ id, username, comentario }) => (
            <article key={id} className="container-opinion">
              <div className="hexagon-container">
                <img
                  src={ImageTestimonials}
                  alt="Hexagon"
                  className="hexagon-image"
                />
              </div>

              <div className="opinion">
                <h6 className="fw-700">{username}</h6>
                <p className="color-p">{comentario}</p>
              </div>
            </article>
          ))}
        </Slider>
      </article>
    </section>
  );
};

export default Testimonials;
