import React, { useEffect, useState } from "react";
import FaqQuestions from "./FaqQuestions";
import { questionList } from "assets/Questions";
import InstagramVideo from "common/InstagramVideo";
import { ReactComponent as IconPlus } from "assets/icons/mas.svg";
import { ReactComponent as IconLess } from "assets/icons/menos.svg";
import { getVideoInstagramGac } from "services/instagram";
import "styles/faq.css";

const FAQ = () => {
  const [activeCategory, setActiveCategory] = useState(null);
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [codeInstagram, setCodeInstagram] = useState(null);

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    setActiveQuestion(null);
  };

  const handleQuestionClick = (question) => {
    setActiveQuestion(question === activeQuestion ? null : question);
  };

  const listCode = async () => {
    try {
      const { data } = await getVideoInstagramGac();
      setCodeInstagram(data);
    } catch (error) {
      console.log(error);
      alert("No se pudo obtener el listado de historias de instagram");
    }
  };
  useEffect(() => {
    listCode();
  }, []);

  return (
    <div className="faq-container">
      <div className="categories">
        {Object.keys(questionList).map((category) => (
          <FaqQuestions
            key={category}
            category={category}
            onClick={handleCategoryClick}
            isActive={category === activeCategory}
          />
        ))}
      </div>
      <div className="questions">
        {activeCategory &&
          questionList[activeCategory].map((item, index) => (
            <div className="accordion" key={index}>
              <div
                className="accordion-header"
                onClick={() => handleQuestionClick(item.question)}
              >
                {item.question}
                {activeQuestion === item.question ? (
                  <IconLess className="icon-questions" />
                ) : (
                  <IconPlus className="icon-questions" />
                )}
              </div>
              <div
                className={`accordion-content ${
                  activeQuestion === item.question ? "open" : ""
                }`}
              >
                <span dangerouslySetInnerHTML={{ __html: item.answer }}></span>
              </div>
            </div>
          ))}
        <article className="container-videos-instagram">
          {codeInstagram?.map((item, index) => (
            <InstagramVideo key={index} embedCode={item} isArrayCode={true} />
          ))}
        </article>
      </div>
    </div>
  );
};

export default FAQ;
