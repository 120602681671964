import React, { useEffect, useState } from "react";
import "styles/pay/payments.css";
import PropTypes from "prop-types";
import PaymentButton from "./PaymentButton";
import { LogPaymentEvent } from "utils/logger";
import { mercadoPago } from "services/Payments";
import { useMercadopago } from "react-sdk-mercadopago";
import { usePaymentGateway } from "hooks/usePaymentGateway";
import { ReactComponent as ArrowIcon } from "assets/icons/left-arrow.svg";

const Selectgateway = ({ setFormConfig, user, setUser }) => {
  const { loading, handlePayu } = usePaymentGateway(
    user,
    setUser,
    setFormConfig
  );

  const [mercadoPagoPreferenceId, setMercadoPagoPreferenceId] = useState(null);
  const [errorComponent, setErrorComponent] = useState(null);
  const [loadingComponent, setLoadingComponent] = useState(false);

  // Initialize MercadoPago SDK
  const mercadopago = useMercadopago.v2(
    process.env.REACT_APP_API_KEY_MERCADOPAGO,
    {
      locale: "es-CO",
    }
  );

  // Fetch the MercadoPago payment preference ID
  const getMercadoPagoId = async () => {
    try {
      setErrorComponent(null);
      setLoadingComponent(true);
      const response = await mercadoPago({
        codigo: user?.codigo,
        contrato: user?.contrato,
        tipo: user?.tipo,
        valor: user?.monto,
      });
      setMercadoPagoPreferenceId(response.data);
    } catch (err) {
      console.error(err);
      setErrorComponent(
        "No se pudo obtener el ID de pago. Intenta nuevamente."
      );
    } finally {
      setLoadingComponent(false);
    }
  };

  // Render the MercadoPago payment button
  useEffect(() => {
    if (mercadoPagoPreferenceId) {
      const container = document.querySelector(".cho-container");
      if (container) {
        mercadopago.checkout({
          preference: {
            id: mercadoPagoPreferenceId,
          },
          autoOpen: true,
          render: {
            container: ".cho-container", // Selector of the button container
            label: "Pagar con MercadoPago",
          },
        });
      } else {
        console.error("El contenedor .cho-container no existe.");
      }
    }
  }, [mercadoPagoPreferenceId, mercadopago]);

  return (
    <div className="payment-gateway-container">
      {/* Error message */}
      {errorComponent && (
        <div className="error-message" role="alert">
          {errorComponent}
        </div>
      )}

      {/* MercadoPago payment button */}
      {mercadoPagoPreferenceId && <div className="cho-container" />}

      <button
        className="btn-back-step"
        onClick={() => {
          LogPaymentEvent("NAVIGATION", "back_step");
          setFormConfig({ step: 3 });
        }}
      >
        <ArrowIcon />
        Regresar
      </button>

      <section className="container-payments">
        <PaymentButton
          onClick={handlePayu}
          loading={loading.payu}
          icon="https://www.svgrepo.com/show/408253/world-map.svg"
          className="international"
          data-testid="payu-button"
        >
          Pagar desde el exterior
        </PaymentButton>

        <PaymentButton
          onClick={getMercadoPagoId}
          icon="https://www.svgrepo.com/show/405455/flag-for-flag-colombia.svg"
          className="national"
          disabled={loadingComponent}
        >
          {loadingComponent ? "Cargando..." : "Pagar desde Colombia"}
        </PaymentButton>
      </section>
    </div>
  );
};

Selectgateway.propTypes = {
  setFormConfig: PropTypes.func.isRequired,
  user: PropTypes.shape({
    contrato: PropTypes.string.isRequired,
    titular: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    monto: PropTypes.number.isRequired,
    codigo: PropTypes.string.isRequired,
    tipo: PropTypes.string.isRequired,
    url_payU: PropTypes.string,
  }).isRequired,
  setUser: PropTypes.func.isRequired,
};

export default Selectgateway;
