import React, { useEffect, useState } from "react";
import Loaging from "common/Loaging";
import { NavLink } from "react-router-dom";
import Table from "../components/common/Tablet";
import { getQuestions, deleteQuestion } from "services/questions";

const columns = [
  { key: "id", label: "#" },
  { key: "categoria", label: "categoria" },
  { key: "pregunta", label: "pregunta" },
  { key: "respuesta", label: "respuesta" },
];

export const PageQuestions = () => {
  const [questions, setQuestions] = useState(null);

  const listQuestions = async () => {
    try {
      const { data } = await getQuestions();
      setQuestions(data);
    } catch (error) {
      console.log(error);
      alert("No se pudo obtener el listado de preguntas");
    }
  };

  const handleDeletesetQuestion = async (id) => {
    try {
      await deleteQuestion(id);
      await listQuestions();
    } catch (error) {
      console.log("error handleDeleteVideo", error);
    }
  };

  useEffect(() => {
    listQuestions();
  }, []);

  return (
    <article>
      <h1 className="centrar">Listado de preguntas</h1>
      <NavLink
        className="btn-about-contact btn-add-banner"
        to="/d77cdf4f4ab48f705fbed94d26c7ee03831add4b9cbe2a4c7c578f8cc7361215/create-questions"
      >
        Agregar pregunta
      </NavLink>

      {!questions ? (
        <Loaging />
      ) : (
        <Table
          columns={columns}
          data={questions}
          onEdit={() => console.log("ACTION")}
          onDelete={handleDeletesetQuestion}
        />
      )}
    </article>
  );
};
