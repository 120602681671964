import React, { useEffect } from "react";
import CustomModal from "common/CustomModal";
import { useModalGac } from "hooks/useModalGac";
import { ReactComponent as CloseIcon } from "assets/icons/components/search/close.svg";
import "styles/pop-up/index.css";

const MainPopUp = () => {
  const [isOpenModal, openModal, closeModal] = useModalGac(false);

  useEffect(() => {
    const popupShowCount = localStorage.getItem("popupShowCountMsj");
    const parsedCount = popupShowCount ? parseInt(popupShowCount, 10) : 0;

    if (parsedCount < 1) {
      openModal();
      localStorage.setItem("popupShowCountMsj", (parsedCount + 1).toString());
    }
  }, [openModal]);

  const handleClose = () => {
    closeModal();
  };

  return (
    <CustomModal isOpen={isOpenModal} closeModal={handleClose}>
      <button className="btn-close-modal" onClick={handleClose}>
        <CloseIcon className="icon-close" />
      </button>

      <div className="centrar">
        <img
          className="img-fluid"
          src="https://53fde1b606cff91e7a3b23ff9c242152.grupoalianzacolombia.com/uploads/mensaje_gac.jpg"
          alt="Grupo Alianza Colombia"
        />
      </div>
    </CustomModal>
  );
};

export default MainPopUp;
