import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
const keyGoogle = process.env.REACT_APP_GOOGLE_ANALYTICS;

const ScrollToTop = ({ step }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    ReactGA.initialize(keyGoogle);
    // Enviar un evento de "pageview"
    ReactGA.send({
      hitType: "pageview",
      page: pathname,
      title: pathname,
    });

    // Hacer scroll hacia el top de la página
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Desplazamiento suave
    });
  }, [pathname]); // Cambia la ruta

  // Si `step` cambia, también se hace scroll hacia el top
  useEffect(() => {
    if (step !== undefined) {
      window.scrollTo({
        top: 150,
        behavior: "smooth", // Esto asegura que el desplazamiento sea suave
      });
    }
  }, [step]);

  return null;
};

export default ScrollToTop;
