import React, { useEffect, useState } from "react";

const InstagramVideo = ({ embedCode, isArrayCode = false }) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  useEffect(() => {
    if (!scriptLoaded) {
      const script = document.createElement("script");
      script.src = "//www.instagram.com/embed.js";
      script.async = true;
      script.onload = () => setScriptLoaded(true);
      document.body.appendChild(script);

      return () => {
        document.body.removeChild(script);
      };
    }
  }, [scriptLoaded]);

  // Use a ref to only execute once after the script is loaded
  useEffect(() => {
    if (scriptLoaded) {
      window.instgrm.Embeds.process();
    }
  }, [scriptLoaded]);

  return (
    <div className="videos-container">
      {isArrayCode ? (
        <div
          key={embedCode.id}
          className="video-wrapper"
          dangerouslySetInnerHTML={{ __html: embedCode.code }}
        />
      ) : (
        <div
          key={embedCode.id}
          className="video-wrapper"
          dangerouslySetInnerHTML={{ __html: embedCode }}
        />
      )}
    </div>
  );
};

export default InstagramVideo;
